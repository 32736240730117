.has-bg-color {
    z-index: auto !important;
}

.hero{
    min-height: 100vh;
    // height: 100vh;
    display: flex;
    align-items: center;
    border-bottom: 0;
    background: #fff;
    font-weight: 400;
    // color: #272341;
    // padding: 0 5.6rem;
    margin-bottom: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.IntroHeading{
    margin: 0px;
}

.IntroHeadingPart2{
    margin-bottom: 30px;
}

.bio{
    color: white;
}

.NameColor{
 color: #2BBF96;
}