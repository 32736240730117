.site-footer {

    &.invert-color {
        color: rgba(255,255,255,0.5);
    }
    // &::before {
    //     content: '';
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     background: get-color(secondary, 3);
    //     clip-path: polygon(5760px calc(100% - 500px), 0 5760px, 0 0, 0 50%);
    //                 // clip-path: polygon(0 0, 5760px calc(100% - 352px), 5760px 5760px, 0 100%);
    //     z-index: -3;
    // }
}

.footerNote{
    width: 200px;
    display: block;
    margin: auto;
    text-align: center;
    border-top: 1px solid rgba(255,255,255,0.5);
}