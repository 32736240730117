.features-tiles-item-image {
  display: inline-flex;
  border-radius: 50%;
  background: get-color(secondary, 3);
}

.features-tiles-item-content {
  h4 {
    @include font-family(heading);
  }
}

.icons {
  width: 64px;
  height: 64px;
  position: relative;
  font-size: 1.5rem;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
}
